<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 400px; width: 100%"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker
      v-for="(location, index) in locations"
      :key="index"
      :lat-lng="latLng(location.lat, location.lng)"
    >
      <l-tooltip :options="{ permanent: true, interactive: true }">
        <div @click="innerClick">
          {{ location.label }}
          <p v-show="showParagraph">
            {{ location.description }}
          </p>
        </div>
      </l-tooltip>
      <l-icon>
        <img src="@/assets/images/leaflet/marker-icon.png" />
      </l-icon>
    </l-marker>
  </l-map>
</template>

<script>
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
} from "@vue-leaflet/vue-leaflet";

export default {
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      zoom: 5, // Adjusted zoom level for zooming out
      center: latLng(24.774265, 46.738586), // Centered on KSA
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  },
  methods: {
    latLng(lat, lng) {
      return latLng(lat, lng);
    },
    innerClick() {
      alert("Click!");
    },
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
