
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import MapComponent from "../Areas/map.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
        MapComponent,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MapComponent,
  },
  data() {
    return {
      DeliveryMen: [],
      page: 1,
      locationsArray: [],
      limit: 20,
      tot_pages: 0,
      searchDeliveryMen: null,
      DeliveryMenSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addDeliveryMen() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addDeliveryMen",
        inputs: [
          { model: "username", type: "text", label: "delivery_men.username" },
          { model: "active", type: "text", label: "delivery_men.active" },
          { model: "longitude", type: "text", label: "delivery_men.longitude" },
          { model: "email", type: "text", label: "delivery_men.email" },
          {
            model: "created_at",
            type: "text",
            label: "delivery_men.created_at",
          },
          { model: "password", type: "text", label: "delivery_men.password" },
          {
            model: "first_name",
            type: "text",
            label: "delivery_men.first_name",
          },
          {
            model: "identity_image",
            type: "text",
            label: "delivery_men.identity_image",
          },
          { model: "full_name", type: "text", label: "delivery_men.full_name" },
          {
            model: "updated_at",
            type: "text",
            label: "delivery_men.updated_at",
          },
          { model: "city_id", type: "text", label: "delivery_men.city_id" },
          {
            model: "refreshtoken",
            type: "text",
            label: "delivery_men.refreshtoken",
          },
          {
            model: "identity_type",
            type: "text",
            label: "delivery_men.identity_type",
          },
          {
            model: "deleted_at",
            type: "text",
            label: "delivery_men.deleted_at",
          },
          { model: "latitude", type: "text", label: "delivery_men.latitude" },
          { model: "last_name", type: "text", label: "delivery_men.last_name" },
          { model: "phone", type: "text", label: "delivery_men.phone" },
          { model: "image", type: "text", label: "delivery_men.image" },
          {
            model: "identity_number",
            type: "text",
            label: "delivery_men.identity_number",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("delivery-men", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editDeliveryMen(app) {
      var data = {
        title: "popups.delivery_men",
        inputs: [
          {
            model: "username",
            type: "text",
            label: "delivery_men.username",
            value: app.username,
          },
          {
            model: "active",
            type: "text",
            label: "delivery_men.active",
            value: app.active,
          },
          {
            model: "longitude",
            type: "text",
            label: "delivery_men.longitude",
            value: app.longitude,
          },
          {
            model: "email",
            type: "text",
            label: "delivery_men.email",
            value: app.email,
          },
          {
            model: "created_at",
            type: "text",
            label: "delivery_men.created_at",
            value: app.created_at,
          },
          {
            model: "password",
            type: "text",
            label: "delivery_men.password",
            value: app.password,
          },
          {
            model: "first_name",
            type: "text",
            label: "delivery_men.first_name",
            value: app.first_name,
          },
          {
            model: "identity_image",
            type: "text",
            label: "delivery_men.identity_image",
            value: app.identity_image,
          },
          {
            model: "full_name",
            type: "text",
            label: "delivery_men.full_name",
            value: app.full_name,
          },
          {
            model: "updated_at",
            type: "text",
            label: "delivery_men.updated_at",
            value: app.updated_at,
          },
          {
            model: "city_id",
            type: "text",
            label: "delivery_men.city_id",
            value: app.city_id,
          },
          {
            model: "refreshtoken",
            type: "text",
            label: "delivery_men.refreshtoken",
            value: app.refreshtoken,
          },
          {
            model: "identity_type",
            type: "text",
            label: "delivery_men.identity_type",
            value: app.identity_type,
          },
          {
            model: "deleted_at",
            type: "text",
            label: "delivery_men.deleted_at",
            value: app.deleted_at,
          },
          {
            model: "latitude",
            type: "text",
            label: "delivery_men.latitude",
            value: app.latitude,
          },
          {
            model: "last_name",
            type: "text",
            label: "delivery_men.last_name",
            value: app.last_name,
          },
          {
            model: "phone",
            type: "text",
            label: "delivery_men.phone",
            value: app.phone,
          },
          {
            model: "image",
            type: "text",
            label: "delivery_men.image",
            value: app.image,
          },
          {
            model: "identity_number",
            type: "text",
            label: "delivery_men.identity_number",
            value: app.identity_number,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("delivery-men", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateDeliveryMen(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "username", type: "text", label: "delivery_men.username" },
          { model: "active", type: "text", label: "delivery_men.active" },
          { model: "longitude", type: "text", label: "delivery_men.longitude" },
          { model: "email", type: "text", label: "delivery_men.email" },
          {
            model: "created_at",
            type: "text",
            label: "delivery_men.created_at",
          },
          { model: "password", type: "text", label: "delivery_men.password" },
          {
            model: "first_name",
            type: "text",
            label: "delivery_men.first_name",
          },
          {
            model: "identity_image",
            type: "text",
            label: "delivery_men.identity_image",
          },
          { model: "full_name", type: "text", label: "delivery_men.full_name" },
          {
            model: "updated_at",
            type: "text",
            label: "delivery_men.updated_at",
          },
          { model: "city_id", type: "text", label: "delivery_men.city_id" },
          {
            model: "refreshtoken",
            type: "text",
            label: "delivery_men.refreshtoken",
          },
          {
            model: "identity_type",
            type: "text",
            label: "delivery_men.identity_type",
          },
          {
            model: "deleted_at",
            type: "text",
            label: "delivery_men.deleted_at",
          },
          { model: "latitude", type: "text", label: "delivery_men.latitude" },
          { model: "last_name", type: "text", label: "delivery_men.last_name" },
          { model: "phone", type: "text", label: "delivery_men.phone" },
          { model: "image", type: "text", label: "delivery_men.image" },
          {
            model: "identity_number",
            type: "text",
            label: "delivery_men.identity_number",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("delivery-men", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.DeliveryMenSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("delivery-men/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.DeliveryMen = res.data;
          console.log("######## files", this.DeliveryMen);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.DeliveryMenSearchMode = false;
      this.get(this.page);
    },

    getDeliveryMen() {
      this.http.get("delivery-men").then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.latitude != "null" && data[i]?.longitude != "null") {
            this.locationsArray.push({
              lat: data[i]?.latitude,
              lng: data[i]?.longitude,
              label: data[i]?.full_name,
              description: "Capital of Saudi Arabia",
            });
          }
        }
        console.log(265, this.locationsArray);
      });
    },
    deleteDeliveryMen(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("delivery-men", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("delivery-men/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.DeliveryMen = res.data;
          console.log(405,this.DeliveryMen)
        });
    },
  },
  created() {
    this.get(1);
    this.getDeliveryMen();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.delivery_men.text')"
    />
    <b-tabs
      content-class="p-4"
      class="pt-2"
      nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom"
    >
      <b-tab class="col" title="Areas Data Table">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="DeliveryMenSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              @click="addDeliveryMen()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("delivery_men.username") }}</th>
              <th scope="col">{{ $t("delivery_men.email") }}</th>
              <th scope="col">{{ $t("delivery_men.phone") }}</th>
              <th scope="col">{{ $t("delivery_men.full_name") }}</th>
              <th scope="col">{{ $t("delivery_men.first_name") }}</th>
              <th scope="col">{{ $t("delivery_men.active") }}</th>
              <th scope="col">{{ $t("delivery_men.current_wight") }}</th>
              <!-- <th scope="col">{{ $t("delivery_men.city_id") }}</th> -->
              <!-- <th scope="col">{{ $t("delivery_men.identity_image") }}</th> -->
              <th scope="col">{{ $t("delivery_men.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in DeliveryMen" :key="app.id" class="text-center">
              <td>{{ app.username }}</td>
              <td>{{ app.email }}</td>
              <td>{{ app.phone }}</td>
              <td>{{ app.full_name }}</td>
              <td>{{ app.first_name }}/{{ app.last_name }}</td>
              <td v-if="app.active == 'true'">active</td>
              <td v-if="app.active != 'true'">not active</td>
              <td>{{ app?.wight }} Kg</td>

              <!-- <td>{{ app.city_id }}</td> -->
              <!-- <td>{{ app.identity_image }}</td> -->
              <td>
                <router-link
                  class="btn btn-success mx-1"
                  :to="'/dm-weight-targets/' + app.id"
                  href="javascript: void(0);"
                  role="button"
                  ><i class="fas fa-list"></i
                ></router-link>
                <a
                  class="btn btn-primary mx-1"
                  @click="editDeliveryMen(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteDeliveryMen(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab title="Areas Map view" class="fa fa-map">
        <MapComponent :locations="locationsArray" />
      </b-tab>
    </b-tabs>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.paginate {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-item {
  display: inline-block;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>




